/* eslint-disable @nx/enforce-module-boundaries */
import { Button } from '@mybridge/ui/button';
import { Heading } from '@mybridge/ui/heading';
import { HStack, Stack } from '@mybridge/ui/layout';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@mybridge/ui/modal';
import { Radio, RadioGroup } from '@mybridge/ui/radio';
import { Text } from '@mybridge/ui/text';
import { useContext, useMemo, useState } from 'react';
import { getUserFullName } from 'v4/lib/commons';
import { PostBoxContext } from '../../context';
import { Box } from '@chakra-ui/react';
import { Controller, useForm } from 'react-hook-form';
import { ArrowRight, TagTick, TickMarkIcon } from '@mybridge/icons';
import { useTranslator } from '@mybridge/ui/custom-hooks/useTranslation';

export const PostBoxBlockReportModal = ({
  postId,
  fromDashboard,
  text = 'team',
  ...props
}) => {
  const [reason, setReason] = useState('');
  const {
    post,
    blockReport,
    userProfileInfo,
    blockReportModal,
    errorExistCompany,
  } = useContext(PostBoxContext);
  const { author, id } = post ?? {};
  const [openModal, setOpenModal] = useState({ open: false, data: '' });
  const { t } = useTranslator();
  const blockReportReasons = useMemo(() => {
    if (text === 'article') {
      return [
        {
          title: t('otherModules.fake_article') || 'Fake article',
        },
        {
          title: t('profileNetwork.postingInappropriateThings') || 'Posting inappropriate things',
        },
        {
          title: t('profileNetwork.somethingNotRight') || "Something's not right",
        },
      ];
    } else {
      return [
        {
          title: t('profileNetwork.pretendingToBeSomeoneElse') || 'Pretending to be someone else',
        },
        {
          title: t('profileNetwork.fakeAccount') || 'Fake account',
        },
        {
          title: t('profileNetwork.postingInappropriateThings') || 'Posting inappropriate things',
        },
        {
          title: t('teamsPage.harassment') || 'Harassment or bullying',
        },
        {
          title: t('profileNetwork.somethingNotRight') || "Something's not right",
        },
      ];
    }
  }, [text]);

  const handleBlockReport = () => {
    const payload = {
      post: id ?? postId,
      reported_by: userProfileInfo?.id,
      message: openModal?.data,
    };
    console.log(payload); // Debugging purposes

    // Submit the block report
    blockReport(payload);

    // Open the success modal
    // setOpenModal(true);
  };
  const { control } = useForm();

  return (
    <>
      {/* First Modal for Block/Report */}
      <Modal size="xl" {...props}>
        <ModalOverlay />
        <ModalContent minW="559px">
          <form>
            <ModalHeader
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              {/* Centering the text */}
              <Text color={'#3D5A80'} fontWeight={400} lineHeight={'24px'}>
                {fromDashboard
                  ? `${t('profilePhotosVideos.report') || "Report"} ${author?.display_name}'s ${t('pages.post') || "Post"}`
                  : `${t('otherModules.report_the') || "Report the"} ${text}`}
              </Text>
              <ModalCloseButton />
            </ModalHeader>
            <hr
              style={{
                width: '90%',
                margin: '0 auto',
                padding: '0 10px',
                border: '1 px solid #F6F6F6',
              }}
            />
            <ModalBody mb={6}>
              <Heading
                pl={1}
                fontSize={'18px'}
                color={'#3D5A80'}
                fontWeight={400}
                lineHeight={'36px'}
              >
                {t('profileNetwork.pleaseSelectAProblem') || "Please select a problem"}
              </Heading>
              <Text mb={6} pl={1}>
                {
                  t('profileNetwork.immediateDangerWarning') || "If someone is in immediate danger, get help before reporting to myBridge. Don't wait."
                }
              </Text>

              <Controller
                name="message"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Stack spacing={6}>
                    {blockReportReasons?.map((rm, rmIndex) => (
                      <Box
                        key={rmIndex}
                        cursor="pointer"
                        _hover={{ bg: 'gray.100' }}
                        display="flex"
                        justifyContent="space-between" // Add this line
                        alignItems="center"
                        onClick={() => {
                          handleBlockReport({ message: rm.title });
                          onChange(rm.title); // Update the form value
                          setOpenModal({ open: true, data: rm.title });

                          // handleOptionSelect(rm);  // Call if you need to handle selection
                        }}
                        pr={2} // Apply padding to the right side only
                        pl={1}
                      >
                        <Box>
                          <Text>{rm.title}</Text>
                        </Box>
                        <ArrowRight ml="auto" />
                      </Box>
                    ))}
                  </Stack>
                )}
              />
            </ModalBody>
            {/* <ModalFooter>
            <HStack justifyContent="flex-end">
              <Button
                variant="secondary"
                onClick={(e) => reportTeamDisc?.onClose?.()}
              >
                Cancel
              </Button>
              <Button
                isLoading={reportTeamLoading}
                variant="primary"
                type="submit"
              >
                Report
              </Button>
            </HStack>
          </ModalFooter> */}
          </form>
        </ModalContent>
        {/* <ModalContent>
          <ModalHeader>
            <ModalCloseButton />
            Block and report this post by {getUserFullName(author)}
          </ModalHeader>
          <ModalBody>
            <Stack>
              <Stack>
                <Heading size="sm" color="brandPrimary.500">
                  Please select a problem
                </Heading>
                <Text>
                  If someone is in immediate danger, get help before reporting to myBridge. Don&apos;t wait.
                </Text>
              </Stack>
              <RadioGroup
                onChange={(value) => setReason(value)}
                py={4}
                value={reason}
              >
                <Stack spacing={4}>
                  {blockReportReasons?.map?.((brr, index) => (
                    <Radio key={index} value={brr?.title}>
                      {brr?.title}
                    </Radio>
                  ))}
                </Stack>
              </RadioGroup>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <HStack justifyContent="flex-end">
              <Button onClick={props?.onClose} variant="secondary">
                Cancel
              </Button>
              <Button
                onClick={handleBlockReport}
                isDisabled={reason.length <= 0}
                variant="primary"
                isLoading={blockReportLoading}
              >
                Submit
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent> */}

        {openModal?.open && !errorExistCompany && (
          <Modal isOpen={openModal?.open} onClose={() => setOpenModal(false)}>
            <ModalOverlay />

            <ModalContent minW="559px">
              <form>
                <ModalHeader
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Box mt={5}>
                    <TickMarkIcon />
                  </Box>

                  {/* Centering the text */}

                  <ModalCloseButton />
                </ModalHeader>
                <ModalBody>
                  <Text
                    color={'#3D5A80'}
                    fontSize={'20px'}
                    fontWeight={400}
                    lineHeight={'24px'}
                    alignItems="center"
                    display="flex"
                    justifyContent="center"
                  >
                    {fromDashboard
                      ? `${t('profilePhotosVideos.report') || "Report"} ${author?.display_name}'s ${t('pages.post') || "Post"}`
                      : `${t('otherModules.report_the') || "Report the"} ${text}`}
                  </Text>
                  <Heading
                    fontSize={'16px'}
                    fontWeight={400}
                    lineHeight={'19.2px'}
                    alignItems="center"
                    justifyContent="center"
                    display="flex"
                    color={'#747474'}
                    padding={'12px'}
                  >
                    {fromDashboard
                      ? `${t('reportBlock.thanksForReporting') || "Thanks for reporting"}  ${
                          author?.display_name}"'s"  ${t('pages.post') || "Post"}
                        `
                      : `${t('reportBlock.thanksForReporting') || "Thanks for reporting"} ${text}`}
                  </Heading>
                  <Box
                    pl={1}
                    bg={'#D9D9D9'}
                    borderRadius={'10px'}
                    display={'flex'}
                    alignItems={'center'} // Center vertically
                    justifyContent={'center'} // Center horizontally
                    gap={1}
                    m={'0 22%'}
                    width="auto" // Dynamic width based on content
                  >
                    <TagTick />
                    <Text>{openModal?.data}</Text>
                  </Box>
                </ModalBody>
                <ModalFooter>
                  <Button
                    onClick={() => {
                      setOpenModal(false);
                      blockReportModal?.onClose?.();
                    }}
                    variant="primary"
                    type="submit"
                    width="100%"
                  >
                    {t('buttons.done') || 'Done'}
                  </Button>
                </ModalFooter>
              </form>
            </ModalContent>
          </Modal>
        )}
      </Modal>

      {/* Second Modal for Success */}
      {/* {openModal && (
        <Modal isOpen={openModal} onClose={() => setOpenModal(false)}>
          <ModalOverlay />
          <ModalContent minW="559px">
            <ModalHeader
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Box mt={5}>
                <TickMarkIcon />
              </Box>
              <ModalCloseButton />
            </ModalHeader>
            <ModalBody>
              <Text
                color={'#3D5A80'}
                fontSize={'20px'}
                fontWeight={400}
                lineHeight={'24px'}
                alignItems="center"
                display="flex"
                justifyContent="center"
              >
               {`Report the ${text}`}
              </Text>
              <Heading
                fontSize={'16px'}
                fontWeight={400}
                lineHeight={'19.2px'}
                alignItems="center"
                justifyContent="center"
                display="flex"
                color={'#747474'}
                padding={'12px'}
              >
                {`Thanks for reporting the ${text}`}
              </Heading>
              <Box
                pl={1}
                bg={'#D9D9D9'}
                borderRadius={'10px'}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
                gap={1}
                m={'0 22%'}
                width="auto"
              >
                <TagTick />
                <Text>{openModal?.data}</Text>
              </Box>
            </ModalBody>
            <ModalFooter>
              <Button
                onClick={() => {
                  setOpenModal(false);
                  props?.onClose?.(); // Close the first modal
                }}
                variant="primary"
                width="100%"
              >
                Done
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )} */}
    </>
  );
};
